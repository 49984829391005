export default class GeoService {
    constructor() {
        this.timeoutslot = undefined;
    }
    async get(query, limit = 1, autocomplete = false, type = undefined) {
        let payload = {
            q: query,
            limit: limit,
            autocomplete: autocomplete
        };

        if(type !== undefined){
            payload.type = type;
        }

        return await window.appData.$http.get('/apx/geo/search', {params: payload})
            .then(res => {
                return {
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: undefined,
                    message: err.message
                };
            })
            ;
    }

    queueGeoloc(query, limit = 1, autocomplete = false, type = undefined, callback = undefined) {
        const self = this;
        if(this.timeoutslot !== undefined){
            clearTimeout(this.timeoutslot);
        }

        this.timeoutslot = setTimeout(() => {
            self.get(query,limit,autocomplete, type)
                .then(resp => {
                    if(callback !== undefined){
                        callback(resp);
                    }
                });
        }, 1000);

    }
}
