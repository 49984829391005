<template>
	<div class="p-grid p-fluid">
		<div class="p-col-12 p-mb-2">
			<div class="p-fluid">
				<h1>Mon profil public</h1>
				<p class="text-muted">Ces informations seront affichées publiquement aux visiteurs de votre profil et
					seront utilisées pour la géolocalisation des applications telles que Waze ou Google Map.
					Vérifiez donc l'exactitude de celles-ci.</p>
			</div>
		</div>


		<div class="p-col-12" style="position: relative">
			<InfoEditor></InfoEditor>
		</div>


	</div>
</template>

<script>
	import InfoEditor from '../components/InfoEditor.vue';
	export default {
		data() {
			return {
				dut: undefined,
			}
		},
		components: {
			InfoEditor
		},
		mounted(){

		},
	}
</script>

<style scoped>

</style>
