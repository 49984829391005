export default class PhotoService {

    async getPublicFiles() {
        return await window.appData.$http.get('/apx/upd/file/public')
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

	getImages() {
        return window.appData.$http.get('assets/layout/data/photos.json')
                .then(res => res.data.data);
    }
}
