<template>
	<div class="selector input-field">
		<div v-if="dropdownValues !== undefined" class="w-100 selector-inner">
			<label for="link_type">Style de lien</label>
			<Dropdown v-model="innerValue" id="link_type" @change="updateValue(e)"
                      :options="dropdownValues" optionLabel="name" placeholder="Sélectionnez un type" />
		</div>
	</div>
</template>

<script>

export default {
	data() {
		return {
			linkTypesArray: [
				{
					name: "Lien classique",
					code: "LINK_DEFAULT",
				},
				{
					name: "Lien image",
					code: "LINK_IMAGE",
				},
				{
					name: "Lien vers fichier",
					code: "LINK_FILE",
				},
			],
			dropdownValues: undefined,
			innerValue: undefined,
		}
	},
	props: {
		value: {}
	},
	created() {

	},
	mounted() {
		const vm = this;
		this.innerValue = this.value;

		let arr = [];
		this.linkTypesArray.forEach(function(item){
			arr.push(item);
			if(item.code === vm.innerValue){
				vm.innerValue = item
			}
		});
		vm.dropdownValues = arr;
	},
	methods: {
		updateValue() {
			this.$emit('input', this.innerValue.code)
		}
	}
}
</script>

<style scoped>

</style>
