export default class StyleService {

    async getStylesheet() {
        return await window.appData.$http.get('/api/stylesheets')
            .then(res => {
                return {
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: undefined,
                    message: err.message
                };
            })
            ;
    }

    async getImage(apiImageIp) {
        return await window.appData.$http.get(apiImageIp)
            .then(res => {
                return {
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: undefined,
                    message: err.message
                };
            })
            ;
    }
}
