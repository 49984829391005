export default class StyleService {

    async updateLink(linkApiId, payload) {
        return await window.appData.$http.put(linkApiId, payload)
            .then(res => {
                if(res.status !== 200 && res.status !== 201){
                    return {
                        status: res.status,
                        error: true,
                        data: res.data
                    };
                }

                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async deleteLink(linkApiId) {
        return await window.appData.$http.delete(linkApiId)
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async createLink() {
        return await window.appData.$http.post('/apx/links', {})
            .then(res => {
                if(res.status !== 200 && res.status !== 201){
                    return {
                        status: res.status,
                        error: true,
                        data: res.data
                    };
                }

                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: undefined,
                    message: err.message
                };
            })
            ;
    }

    async getLink(apiImageIp) {
        return await window.appData.$http.get(apiImageIp)
            .then(res => {
                return {
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: undefined,
                    message: err.message
                };
            })
            ;
    }
}
