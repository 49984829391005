<template>
	<div class="selector input-field">
		<div v-if="innerValue !== undefined" class="w-100 selector-inner">
			<Dropdown v-model="innerValue" id="localitytyper" @change="updateValue(e)"
					  :disabled="disabled"
                      :options="dropdownValues" optionLabel="name" placeholder="Sélectionnez un type" />
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			innerValue: undefined,
			dropdownValues: [
				{
					name: "Rue, Avenue, Quai, Boulevard",
					code: 1,
				},
				{
					name: "Numéro exact",
					code: 2,
				},
				{
					name: "Lieu-dit",
					code: 3,
				},
				{
					name: "Commune",
					code: 4,
				}//*/
			],
		}
	},
	props: {
		value: {},
		disabled: {
			type: Boolean,
			default: false,
		}
	},
	created() {
		switch (this.value) {
			case 2:
				this.innerValue = this.dropdownValues[1];
				break;
			case 3:
				this.innerValue = this.dropdownValues[2];
				break;
			case 4:
				this.innerValue = this.dropdownValues[3];
				break;
			default:
				this.innerValue = this.dropdownValues[0];
		}
	},
	mounted() {

	},
	methods: {
		updateValue() {
			const vm = this;
			this.$emit('input', this.innerValue.code);
			this.$nextTick(() => {
				vm.$emit('change', vm.innerValue.code);
			})
		}
	}
}
</script>

<style scoped>

</style>
