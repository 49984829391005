<template>
	<div class="info-editor p-grid p-fluid" style="width: 100%">


        <div v-if="userData !== undefined && lockedProps !== undefined && userInfo !== undefined" class="p-col-12">
            <div class="card p-fluid">
                <h5>Statut</h5>
                <p class="text-muted">Apparaîtra en premier sur votre profil public, peut être laissé vide.</p>
                <div class="p-field">
                    <Textarea style="width: 100%" v-model="userInfo.statusStr"  id="companyname"  :autoResize="true" rows="2" cols="30" />
                </div>
            </div>

        </div>

		<div class="p-col-12 p-md-6">
			<div class="p-fluid">



				<div v-if="userData !== undefined" class="card p-fluid">
					<h5>Informations personnelles</h5>
					<p class="text-muted">Ces informations sont modifiables dans les options de votre compte.</p>
					<div class="p-field">
						<label for="name1">Nom</label>
						<InputText v-model="userData.lastname" disabled id="name1" type="text" />
					</div>
					<div class="p-field">
						<label for="surname">Prénom</label>
						<InputText v-model="userData.firstname" disabled id="surname" type="text" />
					</div>
					<div class="p-field">
						<label for="poste">Poste</label>
						<InputText v-model="userData.poste" disabled id="poste" type="text" />
					</div>
				</div>

				<div v-if="userData !== undefined && lockedProps !== undefined && userInfo !== undefined" class="card p-fluid">
					<h5>Nom de la société</h5>
					<p class="text-muted">Laissez vide si inexistant</p>
					<div class="p-field">
						<InputText v-model="userInfo.company" :disabled="lockedProps.company"
								   id="companyname" type="text"  />
					</div>
				</div>


				<div v-if="userData !== undefined && lockedProps !== undefined && userInfo !== undefined" class="card p-fluid">
					<h5>Adresse</h5>
					<div class="p-field">
						<LocalityTypeSelector :value="userInfo.addreType"
											  :disabled="lockedProps.addr1"
											  @input="userInfo.addreType = $event" v-on:change="compileRequest"></LocalityTypeSelector>
					</div>
					<div class="p-field">
						<InputText v-model="userInfo.addr1" placeholder="ex : 1 rue Victor Hugo" :disabled="lockedProps.addr1" v-on:input="compileRequest" id="addr1" type="text" style="margin-bottom: 1rem" />
						<InputText v-model="userInfo.addr2" placeholder="complément adresse 1" :disabled="lockedProps.addr2" v-on:input="compileRequest" id="addr2" type="text" style="margin-bottom: 1rem" />
						<InputText v-model="userInfo.addr3" placeholder="complément adresse 2 : étage 2, bureau 12"  :disabled="lockedProps.addr3" id="addr3" type="text" style="margin-bottom: 1rem" />
					</div>
					<div class="p-field">
						<label for="addrPostalCode">Code Postal</label>
						<div v-if="propsError !== undefined && propsError.addrPostalCode !== undefined"
							 class="p-message p-component p-message-warn">
							<div class="p-message-wrapper">
								{{ propsError.addrPostalCode }}
							</div>
						</div>
						<InputText v-model="userInfo.addrPostalCode" :disabled="lockedProps.addrPostalCode" v-on:input="compileRequest"  id="addrPostalCode" type="text" />
					</div>
					<div class="p-field">
						<label for="addrCity">Ville</label>
						<InputText v-model="userInfo.addrCity" :disabled="lockedProps.addrCity" v-on:input="compileRequest" id="addrCity" type="text" />
						<div v-if="citySuggestions !== undefined"
							 class="p-message p-component p-message-warn">
							<div class="p-message-wrapper">
								Voulez vous dire : <a @click.prevent="userInfo.addrCity = citySuggestions; citySuggestions = undefined"
													  href="#">{{ citySuggestions }}</a>
							</div>
						</div>
					</div>
					<div class="p-field">
						<label for="addrCountry">Etat/Province</label>
						<InputText v-model="userInfo.addrCountry" :disabled="lockedProps.addrCountry" v-on:input="compileRequest" id="addrCountry" type="text" />
					</div>


					<div v-if="userHasRole('ROLE_SUPERVISOR') && userHasRole('ROLE_GEOLOC')" class="p-field p-d-flex p-jc-left p-ai-center">
						<InputSwitch id="notifcontact" v-model="userInfo.subAccountGeoloc" />
						<div class="p-as-center p-ml-2 p-switch-label">Activer la géolocalisation pour les comptes<br>
							<small class="text-muted">Permettre aux sous-comptes d'utiliser la fonction de géolocalisation</small></div>
					</div>

					<div v-if="userHasRole('ROLE_GEOLOC') && !lockedProps.addr1" class="p-field" style="position: relative;width:100%; height: 40vh;">
						<MapDisplay :value="userInfo.coordinates"
									:markers="mapMarkers"
									@input="userInfo.coordinates = $event"
						></MapDisplay>
					</div>
				</div>

				<div v-if="userData !== undefined && lockedProps !== undefined && userInfo !== undefined" class="card p-fluid">
					<h5>E-mail & Téléphone(s)</h5>
					<div class="p-field">
						<label for="phone1">Téléphone 1</label>
						<div v-if="propsError !== undefined && propsError.phone1 !== undefined"
							 class="p-message p-component p-message-warn">
							<div class="p-message-wrapper">
								{{ propsError.phone1 }}
							</div>
						</div>
						<InputText v-model="userInfo.phone1" :disabled="lockedProps.phone1" id="phone1" type="text" />
					</div>
					<div class="p-field">
						<label for="phone2">Téléphone 2</label>
						<div v-if="propsError !== undefined && propsError.phone2 !== undefined"
							 class="p-message p-component p-message-warn">
							<div class="p-message-wrapper">
								{{ propsError.phone2 }}
							</div>
						</div>
						<InputText v-model="userInfo.phone2" :disabled="lockedProps.phone2" id="phone2" type="text" />
					</div>
					<div class="p-field">
						<label for="email">Email de contact</label>
						<div v-if="propsError !== undefined && propsError.email !== undefined"
							 class="p-message p-component p-message-warn">
							<div class="p-message-wrapper">
								{{ propsError.email }}
							</div>
						</div>
						<InputText v-model="userInfo.email" :disabled="lockedProps.email" id="email" type="text" />
					</div>
				</div>


				<div v-if="userData !== undefined && lockedProps !== undefined && userInfo !== undefined" class="card p-fluid">
					<h5>Site Web & Réseaux Sociaux</h5>
					<div class="p-field">
						<label for="urlWebsite">Site Internet</label>
						<div v-if="propsError !== undefined && propsError.urlWebsite !== undefined"
							 class="p-message p-component p-message-warn">
							<div class="p-message-wrapper">
								{{ propsError.urlWebsite }}
							</div>
						</div>
						<InputText v-model="userInfo.urlWebsite" :disabled="lockedProps.urlWebsite" id="urlWebsite" placeholder="www.site.com ou https://site.com" type="text" />
					</div>
					<div class="p-field">
						<label for="urlFacebook">Facebook <br><a
								target="_blank" rel="noreferrer"
								href="https://knowledgebase.constantcontact.com/articles/KnowledgeBase/6069-trouver-lURL-dun-profil-Facebook-ou-dune-page-pro?lang=fr"
						><i>Comment copier l'URL d'une page Facebook</i></a></label>
						<div v-if="propsError !== undefined && propsError.urlFacebook !== undefined"
							 class="p-message p-component p-message-warn">
							<div class="p-message-wrapper">
								{{ propsError.urlFacebook }}
							</div>
						</div>
						<InputText v-model="userInfo.urlFacebook" :disabled="lockedProps.urlFacebook" id="urlFacebook" type="text" />
					</div>
					<div class="p-field">
						<label for="urlInstagram">Instagram <br><a
								target="_blank" rel="noreferrer"
								href="https://trobweb.com/fr/2020/11/copy-link-of-my-instagram-account.html"
						><i>Comment copier l'URL d'une page Instagram</i></a></label>
						<div v-if="propsError !== undefined && propsError.urlInstagram !== undefined"
							 class="p-message p-component p-message-warn">
							<div class="p-message-wrapper">
								{{ propsError.urlInstagram }}
							</div>
						</div>
						<InputText v-model="userInfo.urlInstagram" :disabled="lockedProps.urlInstagram" id="urlInstagram" type="text" />
					</div>
					<div class="p-field">
						<label for="urlLinkedin">Linkedin <br><a
								target="_blank" rel="noreferrer"
								href="https://www.linkedin.com/help/linkedin/answer/49933/trouver-l-url-de-votre-profil-public-linkedin?lang=fr#:~:text=Cliquez%20sur%20l'ic%C3%B4ne%20Vous,URL%20de%20votre%20profil%20public."
						><i>Comment copier l'URL d'un profil Linkedin</i></a></label>
						<div v-if="propsError !== undefined && propsError.urlLinkedin !== undefined"
							 class="p-message p-component p-message-warn">
							<div class="p-message-wrapper">
								{{ propsError.urlLinkedin }}
							</div>
						</div>
						<InputText v-model="userInfo.urlLinkedin" :disabled="lockedProps.urlLinkedin" id="urlLinkedin" type="text" />
					</div>
					<div class="p-field">
						<label for="urlPinterest">Pinterest <br><a
								target="_blank" rel="noreferrer"
								href="https://faq.cyberimpact.com/fr/articles/248/comment-trouver-vos-liens-de-reseaux-sociaux"
						><i>Comment copier l'URL d'un profil Pinterest</i></a></label>
						<div v-if="propsError !== undefined && propsError.urlPinterest !== undefined"
							 class="p-message p-component p-message-warn">
							<div class="p-message-wrapper">
								{{ propsError.urlPinterest }}
							</div>
						</div>
						<InputText v-model="userInfo.urlPinterest" :disabled="lockedProps.urlPinterest" id="urlPinterest" type="text" />
					</div>

                    <div class="p-field">
                        <label for="urlTwitter">Twitter <br><a
                                target="_blank" rel="noreferrer"
                                href="https://faq.cyberimpact.com/fr/articles/248/comment-trouver-vos-liens-de-reseaux-sociaux"
                        ><i>Comment copier l'URL d'un profil Twitter</i></a></label>
                        <div v-if="propsError !== undefined && propsError.urlTwitter !== undefined"
                             class="p-message p-component p-message-warn">
                            <div class="p-message-wrapper">
                                {{ propsError.urlTwitter }}
                            </div>
                        </div>
                        <InputText v-model="userInfo.urlTwitter" :disabled="lockedProps.urlTwitter" id="urlTwitter" type="text" />
                    </div>

					<div class="p-field">
						<label for="urlInstagram">Whatsapp</label>
						<div v-if="propsError !== undefined && propsError.urlWhatsapp !== undefined"
							 class="p-message p-component p-message-warn">
							<div class="p-message-wrapper">
								{{ propsError.urlWhatsapp }}
							</div>
						</div>
						<InputText v-model="userInfo.urlWhatsapp" :disabled="lockedProps.urlWhatsapp" id="urlWhatsapp" type="text" />
					</div>

                    <div class="p-field">
                        <label for="urlTiktok">TikTok <br><a
                                target="_blank" rel="noreferrer"
                                href="https://faq.cyberimpact.com/fr/articles/248/comment-trouver-vos-liens-de-reseaux-sociaux"
                        ><i>Comment copier l'URL d'un profil Tiktok</i></a></label>
                        <div v-if="propsError !== undefined && propsError.urlTiktok !== undefined"
                             class="p-message p-component p-message-warn">
                            <div class="p-message-wrapper">
                                {{ propsError.urlTiktok }}
                            </div>
                        </div>
                        <InputText v-model="userInfo.urlTiktok" :disabled="lockedProps.urlTiktok" id="urlTiktok" type="text" />
                    </div>
				</div>

			</div>
		</div>

		<!-- 2nd row -->
		<div class="p-col-12 p-md-6">
				<div v-if="userData !== undefined && lockedProps !== undefined && userInfo !== undefined" class="card p-fluid">
					<h5>RGPD </h5>
					<p class="text-muted">Sélectionnez le cas qui correspond à votre utilisation des données collectées.
						Si vous ne savez pas ce que vous faites, laissez l'option par défaut.</p>
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-field-radiobutton">
								<RadioButton id="option1" name="option" :disabled="lockedProps.rGPDStatus"  :value="1" v-model="userInfo.rGPDStatus" />
								<label for="option1">Case à cocher pour le consentement aux communications ; soumission du formulaire
									équivalent au consentement pour le traitement des données<br>
									<small class="text-muted">Afficher les cases à cocher de consentement de communication et consentir à traiter le texte</small>
								</label>
							</div>
						</div>
						<div class="p-col-12">
							<div class="p-field-radiobutton">
								<RadioButton id="option2" name="option" :disabled="lockedProps.rGPDStatus"  :value="2" v-model="userInfo.rGPDStatus" />
								<label for="option2">Afficher les cases à cocher de consentement de communication et de traitement<br>
									<small class="text-muted">Afficher les cases à cocher de consentement de communication et de traitement</small>
								</label>
							</div>
						</div>
						<div class="p-col-12">
							<div class="p-field-radiobutton">
								<RadioButton id="option3" name="option" :disabled="lockedProps.rGPDStatus"  :value="0" v-model="userInfo.rGPDStatus" />
								<label for="option3">Intérêt légitime<br>
									<small class="text-muted">Afficher le texte de confidentialité</small>
								</label>
							</div>
						</div>
					</div>
				</div>


			<div v-if="userData !== undefined && lockedProps !== undefined && userInfo !== undefined" class="card p-fluid">
				<h5>Style & Affichage</h5>
				<p class="text-muted">Personnalisez le look de votre profil public. Changez votre image de profil et d'en-tête pour vous démarquer.</p>

				<div class="p-grid">
					<div class="p-col-12">
						<div class="p-field-radiobutton">
							<StylesheetSelector :value="userInfo.stylesheet" @input="userInfo.stylesheet = $event"></StylesheetSelector>
						</div>
					</div>
					<div class="p-col-12">
						<div  class="p-field">
							<header-uploader :value="userInfo.bgImgName"
                                             @input="userInfo.bgImgName = $event"
											 v-if="!lockedProps.bgImgName" type="TYPE_HEADER"></header-uploader>
							<div v-else  class="p-fluid">
								<p class="text-muted">Image d'en-tête</p>
								<img :src="userInfo.bgImgName" style="max-width: 90%;margin:auto;display:block" alt="">
							</div>

						</div>
					</div>

					<div class="p-col-12">
						<div  class="p-field">
							<profile-uploader :value="userInfo.profilImgName"
											 @input="userInfo.profilImgName = $event"
											 v-if="!lockedProps.profilImgName" type="TYPE_PROFILE"></profile-uploader>
							<div v-else  class="p-fluid">
								<p class="text-muted">Image de profil</p>
								<img :src="userInfo.profilImgName" style="max-width: 90%;margin:auto;display:block" alt="">
							</div>
						</div>
					</div>

				</div>
			</div>


			<div v-if="userData !== undefined && lockedProps !== undefined && userInfo !== undefined" class="card p-fluid">
				<LinkManager :links="userInfo.links"
							 :order="userInfo.linksOrder"
							 @drag="userInfo.linksOrder = $event"
				></LinkManager>
			</div>

		</div>

		<!-- full width row -->
		<div  v-if="userData !== undefined && lockedProps !== undefined && userInfo !== undefined" class="p-col-12">
			<div class="card">
				<div v-if="apiKey !== undefined && htmlContent !== undefined" class="w-100">
					<Editor :disabled="lockedProps.presentation" :init="editorConfig" v-model="htmlContent" :apiKey="apiKey"></Editor>
				</div>
			</div>
		</div>


		<div class="p-col-12">
			<div class="p-fluid">
				<div class="w-50">
					<Button :disabled="loading" label="Mettre à jour" icon="pi pi-save" @click="updateInfo" class="b-button p-m-2"></Button>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import ProfileService from "../service/ProfileService";
	import StylesheetSelector from "../components/app/style/StylesheetSelector.vue";
	import LocalityTypeSelector from "../components/app/addr/LocalityTypeSelector.vue";
	import HeaderUploader from "../components/app/style/HeaderUploader.vue";
	import ProfileUploader from "../components/app/style/ProfileUploader.vue";
	import userMixin from "../mixins/userMixin";
	import MapDisplay from "../components/app/addr/MapDisplay.vue";
	import GeoService from "../service/GeoService";
	/**
	 * @see https://www.tiny.cloud/blog/tinymce-vue-3-support/
	 */
	import Editor from '@tinymce/tinymce-vue'

	import Turndown from 'turndown';
	import showdown from 'showdown';

	import LinkManager from '../components/app/links/LinkManager.vue';


	export default {
	data() {
		return {
			lockedProps: undefined,
			userInfo: undefined,
			propsError: {},
			citySuggestions: undefined,
			mapMarkers: [],
			apiKey: undefined,
			editorConfig: {
				menubar: false,
				plugins: 'lists link image emoticons',
				toolbar: 'styleselect | bold underline italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image emoticons',
				height : "480"
			},
            htmlContent: undefined,
			requests: 0,
		}
	},
	created(){
		this.profileService = new ProfileService();
		this.geoService = new GeoService();
		this.apiKey = window.appData.tiny.apiKey;
	},
	mounted(){
		const vm = this;

		this.profileService.getLockedProps()
				.then(data => {
					if(data.error === true){
						throw new Error(data.message);
					}
					vm.lockedProps = data.data;
					this.waitUser();
				})
				.catch(err => {
					console.warn('err',err);
					vm.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
				});


	},
	watch: {

	},
	methods: {
		//LEAFLET
		compileRequest(){
			const vm =  this;

			let query = '';
			let type = undefined;


			//bulding query from info
			//ignoring addr3 as it is only for human
			query += vm.userInfo.addr1 + ' ' + vm.userInfo.addr2 + ' ';
			query += vm.userInfo.addrPostalCode + ' ' + vm.userInfo.addrCity + ' ' + vm.userInfo.addrCountry;

			switch (vm.userInfo.addressType) {
				case 1:
					type = 'street';
					break;
				case 2:
					type = 'housenumber';
					break;
				case 3:
					type = 'locality';
					break;
				case 4:
					type = 'municipality';
					break;
				default:
					type = undefined;
			}

			if(query.length <= 5){
				return;
			}

			vm.geoService.queueGeoloc(query,1,false, type,(resp) => {
				if(resp.error === true){
					//diplay error message
					vm.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail: resp.message , life: 3000});
					return;
				}

				vm.citySuggestions = undefined;
				vm.mapMarkers = [];

				try {
					const result = resp.data.data.results[0];
					console.log(result);
					vm.userInfo.coordinates = result.geo;
					vm.mapMarkers.push({
						lat: result.geo.lat,
						lon: result.geo.lon,
						label: 'localisation',
					});

					if(vm.userInfo.addrCity !== result.props.city){
						vm.citySuggestions = result.props.city;
					}

				} catch (e) {
					vm.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:e || 'erreur inconnue', life: 3000});
					return;
				}

			})
		},
		//INFO
		clearErrors() {
			this.propsError = {};
		},
		onClick(event){
			event.preventDefault();
		},
		waitUser(){
			const vm = this;
			if(this.userData === undefined){
				setTimeout(() => {
					vm.waitUser();
				}, 100);
				return;
			}
			vm.loadUserInfo();
		},
		loadUserInfo(){
			const vm = this;
			this.profileService.getInfo(vm.userData.info_id)
					.then(data => {
						if(data.error === true){
							throw new Error(data.message);
						}
						vm.userInfo = data.data;

						//*
						for (let prop in vm.lockedProps['@values']) {
							vm.userInfo[prop] = vm.lockedProps['@values'][prop];
						}//*/

						/**
						 * @see https://github.com/showdownjs/showdown
						 * @type {Converter}
						 */
						let converter = new showdown.Converter();
						vm.htmlContent = converter.makeHtml(vm.userInfo.presentation);



						if(vm.userInfo.coordinates !== undefined && vm.userInfo.coordinates.lat !== undefined){
							vm.mapMarkers.push({
								lat: vm.userInfo.coordinates.lat,
								lon: vm.userInfo.coordinates.lon,
								label: 'localisation',
							});
						}

					})
					.catch(err => {
						vm.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
		},
        updateInfo() {
		    console.log(this.userInfo);
			const vm = this;
			vm.requests++;
			this.clearErrors();


			/**
			 * @see https://github.com/domchristie/turndown
			 * @type {TurndownService}
			 */
			let ts = new Turndown();
			vm.userInfo.presentation = ts.turndown(vm.htmlContent);

			let ui = JSON.parse(JSON.stringify(this.userInfo));

			for (let prop in vm.lockedProps['@values']) {
				delete ui[prop];
			}

			this.profileService.updateInfo(vm.userData.info_id, ui)
					.then(data => {
						console.log(data);
						if(data.error === true && data.data.violations === undefined){
							throw new Error('BO : ' + data.message);
						}

						if(data.data.violations !== undefined){
							//todo display errors
							vm.$toast.add({severity:'error', summary: 'Erreurs dans les données', detail:'Des erreurs sont présente dans le formulaire, merci des les corriger et de sauvegarder à nouveau', life: 3000});
							const errs = {};
							data.data.violations.forEach((item) => {
								errs[item.propertyPath] = item.message;
							});
							vm.propsError = errs;
							console.log(vm.propsError);
							return;
						}
						vm.userInfo = data.data;
						vm.$toast.add({severity:'success', summary: 'Succès', detail:'Informations mises à jour !', life: 3000});
					})
					.catch(err => {
						console.warn('err',err);
						vm.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
					.finally(() => {
						vm.requests--;
					})
        }
	},
	computed: {
		...mapState({
			userData: 'user',
		}),
		loading(){
			return this.requests > 0;
		}
	},
	components: {
		StylesheetSelector,
		HeaderUploader,
		ProfileUploader,
		LocalityTypeSelector ,
		MapDisplay,
		Editor,
		LinkManager
	},
	mixins: [userMixin]
}
</script>

<style lang="scss" >
	.text-muted {
		opacity: .6;
	}
</style>
