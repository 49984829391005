<template>
	<div class="selector input-field">
		<div v-if="dropdownValues !== undefined" class="w-100 selector-inner">
			<label for="public_files_selector">Médias Publics</label>
			<Dropdown v-model="innerValue" id="public_files_selector" @change="updateValue(e)"
                      :options="dropdownValues" optionLabel="name" placeholder="Sélectionnez un fichier" />
		</div>
	</div>
</template>

<script>
import PhotoService from "../../../service/PhotoService";

export default {
	data() {
		return {
			dropdownValues: undefined,
			innerValue: undefined,
		}
	},
	props: {
		value: {}
	},
	created() {
		this.photoService = new PhotoService();
	},
	mounted() {
		const vm = this;
		this.innerValue = this.value;

		this.photoService.getPublicFiles()
				.then(res => {
					if(res.error === true){
						throw new Error(res.message);
					}

					let arr = [];

					res.data.files.forEach(function(File){
						arr.push({
							name: File.name,
							code: File['@id'],
						});
						if( File['@id'] === vm.innerValue){
                            vm.innerValue = {
								name: File.name,
								code: File['@id'],
                            }
                        }
					});

					vm.dropdownValues = arr;
				})
				.catch(err => {
					console.error(err.message);
				})
	},
	methods: {
		updateValue() {
			this.$emit('input', this.innerValue.code)
		}
	}
}
</script>

<style scoped>

</style>
