<template>
	<div style="width: 100%" class="selector input-field">
		<div v-if="dropdownValues !== undefined" class="w-100 selector-inner">
			<label for="stylesheets">Template du profil</label>
			<Dropdown v-model="innerValue" id="stylesheets" @change="updateValue(e)"
                      :options="dropdownValues" optionLabel="name" placeholder="Sélectionnez un style" />
		</div>
		<div class="p-grid p-mt-3">
			<div class="p-col-12">
				<div v-if="innerValue !== undefined && imgUrl !== ''" style="width: 100%; max-width: 90px; margin:auto;" class="img-content">
					<img style="width: 100%" :src="imgUrl" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import StyleService from "../../../service/StyleService";

export default {
	data() {
		return {
			dropdownValues: undefined,
			innerValue: undefined,
		}
	},
	props: {
		value: {}
	},
	created() {
		this.styleService = new StyleService();
	},
	mounted() {
		const vm = this;
		this.innerValue = this.value;

		this.styleService.getStylesheet()
				.then(res => {
					if(res.error === true){
						throw new Error(res.message);
					}

					let arr = [];

					res.data['hydra:member'].forEach(function(item){
						arr.push({
							name: item.label,
							code: item.filename,
						});
						if(item.filename === vm.innerValue){
                            vm.innerValue = {
                                name: item.label,
                                code: item.filename,
                            }
                        }
					});

					vm.dropdownValues = arr;
				})
				.catch(err => {
					console.error(err.message);
				})
	},
	methods: {
		updateValue() {
			this.$emit('input', this.innerValue.code)
		}
	},
	computed: {
		imgUrl(){
			if(this.innerValue === undefined || this.innerValue.code === undefined){
				return '';
			}
			return '/assets/images/preview/profil/' + this.innerValue.code + '.jpg';
		}
	}
}
</script>

<style scoped>

</style>
