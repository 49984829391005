<template>
	<div class="map w-100">
		<l-map
				v-model:zoom="zoomInner"
				:center="getValidCoordinates"
		>
			<l-tile-layer
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			></l-tile-layer>

			<l-marker v-for="marker in markers" :key="marker.lat + '_' + marker.lon" :lat-lng="[marker.lat, marker.lon]">
			</l-marker>
		</l-map>
	</div>
</template>

<script>
	import { LMap, LTileLayer, LMarker } from '@vue-leaflet/vue-leaflet';
	import "leaflet/dist/leaflet.css";

export default {
	data() {
		return {
			zoomInner: 5,
			iconWidth: 25,
			iconHeight: 40,
		}
	},
	computed: {
		getValidCoordinates(){
			if(this.value === undefined || this.value.lat === undefined){
				return [48.856614, 	2.3522219]
			}
			return [this.value.lat,this.value.lon];
		},
	},
	props: {
		value:{},
		zoom: {
			type: Number,
			default: 12
		},
		markers: {}
	},
	created(){
		this.zoomInner = this.zoom;
	},
	mounted(){

	},
	methods: {
	},
	components: {
		LMap, LTileLayer, LMarker
	},
}
</script>

<style scoped>
	.map {
		position: absolute;
		width: 100%;
		height: 100%;
		overflow :hidden
	}
</style>
