<template>
	<div class="p-fluid link-manager">
		<InfiniteProgress v-show="loading || subLoading"></InfiniteProgress>
		<div class="p-fluid">
			<h5>Liens et Médias</h5>
			<p class="text-muted">Ajouter des liens vers des pages web ou des médias à votre profil FreeCard.</p>

			<div v-if="objects!== undefined && !loading" class="w-100">
				<draggable
						class="links-draggable-container"
						v-model="objects"
						group="links"
						@start="drag=true"
						@end="dragEnd"
						item-key="id">
					<template #item="{element}">
						<div class="p-grid p-m-2 draggable-el-style">
							<div class="p-col-6 el-label" style="text-align: left" >{{ element.text }}</div>
							<div class="p-col-6" style="text-align: right" >
								<a v-if="!element.selected" @click.prevent="selectElement(element)" href="#">modifier <span class="pi pi-pencil"></span></a>
								<a v-else @click.prevent="element.selected = false" href="#">fermer <span class="pi pi-times"></span></a>
							</div>
							<div v-show="element.selected" class="p-col-12 content ">
								<div class="p-grid p-m-2">
									<div class="p-col-12">
										<h5>Options du lien</h5>
										<div class="p-field">
											<LinkTypeSelector @input="element.type = $event" :value="element.type"></LinkTypeSelector>
										</div>
										<div class="p-field">
											<label :for="'lnk_text_' + element.id" >Texte</label>
											<InputText v-model="element.text" placeholder="titre de mon lien" :id="'lnk_text_' + element.id" type="text" />
										</div>
										<div v-if="element.type === 'LINK_DEFAULT' || element.type === 'LINK_IMAGE'" class="p-field">
											<label :for="'lnk_url_' + element.id" >URL</label>
											<InputText v-model="element.url" placeholder="https://site.com/page" :id="'lnk_url_' + element.id"  type="text" />
										</div>
										<div class="p-field">
											<label :for="'lnk_alt_' + element.id">Texte Alternatif <small class="text-muted">utilisé pour l'accessibilité</small></label>
											<InputText v-model="element.alt" :id="'lnk_alt_' + element.id" type="text" />
										</div>
										<div v-if="element.type === 'LINK_FILE'" class="p-field">
											<PublicFilesSelector @input="element.fileLink = $event" :value="element.fileLink"></PublicFilesSelector>
										</div>
										<div v-if="element.type === 'LINK_IMAGE'" class="p-field">
											<LinkUploader :value="element.bgImgName"
														  @input="element.bgImgName = $event"
														  type="TYPE_LINK"></LinkUploader>
										</div>
									</div>
									<div class="p-col-12">
										<div class="p-grid p-nogutter">
											<div class="p-col-6 p-p-3">
												<Button	@click="element.dialog = true" class="p-button-danger" label="Supprimer" icon="pi pi-trash"></Button>
												<Dialog header="Confirmation" v-model:visible="element.dialog" :style="{width: '350px'}" :modal="true">
													<div class="confirmation-content">
														<i class="pi pi-exclamation-triangle p-mr-3" style="display:block; font-size: 2rem" />
														<span>Êtes-vous sur de vouloir supprimer ce lien ?</span>
													</div>
													<template #footer>
														<Button label="Non" icon="pi pi-times" @click="element.dialog = false" class="p-button-text"/>
														<Button label="Oui, supprimer" icon="pi pi-check" @click="deleteLink(element); element.dialog = false"
																class="p-button-text" autofocus />
													</template>
												</Dialog>
											</div>
											<div class="p-col-6 p-p-3">
												<Button	@click="updateLink(element)" label="Editer ce lien" icon="pi pi-save"></Button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>
				</draggable>
				<div class="p-grid p-m-2 control text-muted">
					<Button @click.prevent.stop="addNewLink" label="Ajouter un lien" class="p-button p-button-secondary"></Button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
import LinkService from "../../../service/LinkService";
import InfiniteProgress from '../../InfiniteProgress.vue';
import LinkTypeSelector from './LinkTypeSelector.vue';
import PublicFilesSelector from '../files/PublicFilesSelector.vue';
import LinkUploader from '../links/LinkUploader.vue';
	/**
	 * @see https://github.com/SortableJS/vue.draggable.next
	 */
	import draggable from 'vuedraggable';

function compare( a, b ) {
	if ( a.order < b.order ){
		return -1;
	}
	if ( a.order > b.order ){
		return 1;
	}
	return 0;
}

export default {
	data() {
		return {
			innerOrder: [],
			innerLinks: undefined,
			objects: [],
			requests: 0,
			subRequests: 0,
			drag: false,
		}
	},
	props: {
		order: {},
		links: {},
	},
	created(){
		this.linkService = new LinkService();
		this.innerLinks = this.links;
	},
	mounted(){
		this.waitUser();
	},
	methods: {
		deleteLink(element) {
			const vm = this;
			this.requests++;
			this.linkService.deleteLink("/api/links/" + element.id)
					.then(data => {
						if(data.error === true || data.data === undefined){
							throw new Error(data.message);
						}

						let lks = [];

						vm.innerLinks.forEach(lk => {
							if(lk !== "/api/links/" + element.id){
								lks.push(lk);
							}
						});

						vm.innerLinks = lks;

						vm.$toast.add({severity:'success', summary: 'Suppression effectué', detail:'Le lien à été supprimé', life: 3000});

						vm.loadup();
					})
					.catch(err => {
						console.warn('err',err);
						vm.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
					.finally(() => {
						vm.requests--;
					})
		},
		addNewLink(){
			const vm = this;
			this.requests++;
			this.linkService.createLink()
					.then(data => {
						if(data.error === true || data.data === undefined){
							throw new Error(data.message);
						}
						vm.objects.push(data.data);
						vm.innerLinks.push("/api/links/" + data.data.id)
					})
					.catch(err => {
						console.warn('err',err);
						vm.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
					.finally(() => {
						vm.requests--;
					})
		},
		updateLink(element){
			const vm = this;
			vm.subRequests++;
			vm.linkService.updateLink('/api/links/' + element.id, element)
					.then((data) => {
						if(data.error === true){
							//throw err
							console.error('une erreur est survenue');
							vm.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:data.message, life: 3000});
							return;
						}
						element = data.data;

						vm.$toast.add({severity:'success', summary: 'Mise à jour effectué', detail:'Le lien à été mis à jour !', life: 3000});
					})
					.catch(err => {
						// throw err
						console.error(err);
						vm.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
					.finally(() => {
						vm.subRequests--;
					})
		},
		selectElement(el){
			for (let i = 0; i < this.objects.length; i++) {
				this.objects[i].selected = false;
				if(this.objects[i] === el){
					this.objects[i].selected = true;
				}
			}
		},
		dragEnd(){
			this.drag = false;
			this.updateOrder();
		},
		updateOrder(){
			//compute order =>
			let order__ = {};
			let i = 1;
			this.objects.forEach(lnk => {
				order__[lnk['@id']] = i;
				i++;
			});
			this.innerOrder = order__;
			this.$emit('drag',this.innerOrder);
		},
		waitUser(){
			const vm = this;
			if(this.userData === undefined){
				setTimeout(() => {
					vm.waitUser();
				}, 100);
				return;
			}
			vm.loadup();
		},
		loadup(){
			this.loadAllLinks();
		},
		loadAllLinks(){
			this.requests++;
			this.objects = [];
			this.innerLinks.forEach(lnkId => {
				this.loadLink(lnkId);
			});
			this.requests--;
		},
		endLoadingLinks(){
			this.objects.sort( compare );
		},
		loadLink(linkApiId){
			const vm = this;
			this.requests++;
			this.linkService.getLink(linkApiId)
					.then(data => {
						if(data.error === true || data.data === undefined){
							throw new Error(data.message);
						}
						vm.objects.push(data.data);
						if(vm.objects.length >= vm.links.length){
							vm.endLoadingLinks();
						}
					})
					.catch(err => {
						console.warn('err',err);
						vm.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
					.finally(() => {
						vm.requests--;
					})
		}
	},
	computed: {
		...mapState({
			userData: 'user',
		}),
		loading(){
			return this.requests > 0;
		},
		subLoading(){
			return this.subRequests > 0;
		}
	},
	components: {
		InfiniteProgress,
		LinkTypeSelector,
		PublicFilesSelector,
		LinkUploader,
		draggable,
	}
}
</script>

<style lang="scss" scoped>
	.draggable-el-style {
		border-radius: 4px;
		border: 1px solid rgba(0,0,0,.03);
		background-color: rgba(0,0,0,.05) ;

		&.control {
			opacity:.6;
		text-align:center;
		cursor: pointer;
			 transition: opacity 220ms ease-in-out;

			&:hover {
				opacity:1
			 }
		 }

		.content {
			background-color: rgba(0,0,0,.05) ;
		}
	}
</style>
