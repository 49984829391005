<template>
	<div class="uploader header-upd input-field">
		<div class="w-100 selector-inner">
			<h6>Image d'en-tête</h6>
			<div class="w-100 p-grid p-m-2">
				<div class="p-col-12">
					<div class="img-render w-100" style="margin-bottom:1rem;">
						<div v-if="cropping">
							<vue-cropper :aspect-ratio="205/78" :zoomable="false" ref="cropper" class="cropper" :src="img" @crop="updateValue"></vue-cropper>
						</div>
						<div style="position: relative" v-else>
							<img  style="max-width: 100%" v-if="imgDisplay !== undefined && imgDisplay !== ''" :src="imgDisplay" alt="">
						</div>
					</div>
					<div class="hidden" v-show="false">
						<input accept=".jpg,.jpeg,.png" @change="prepareCropImage" ref="inputFile" type="file" >
					</div>
					<div class="w-100">
						<div v-if="!cropping" class="w-100">
							<Button label="Uploader une image" icon="pi pi-pencil" @click="proxyClick" class="p-mr-2 p-mb-2"></Button>
						</div>
						<div v-else class="w-100">
							<Button label="Upload" icon="pi pi-check" @click="cropImage" class="p-mr-2 p-mb-2"></Button>
							<Button label="Annuler" icon="pi pi-cancel" @click="cancelCrop" class="p-mr-2 p-button-danger p-mb-2"></Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import StyleService from "../../../service/StyleService";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
/*
The cover photo size is 820 pixels wide by 312 pixels tall on desktop.
However, mobile users will see 640 pixels wide by 360 pixels tall.
*/

function dataURItoBlob(dataURI) {
	// convert base64 to raw binary data held in a string
	// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
	var byteString = atob(dataURI.split(',')[1]);

	// separate out the mime component
	var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	// write the bytes of the string to an ArrayBuffer
	var ab = new ArrayBuffer(byteString.length);
	var ia = new Uint8Array(ab);
	for (var i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	//Old Code
	//write the ArrayBuffer to a blob, and you're done
	//var bb = new BlobBuilder();
	//bb.append(ab);
	//return bb.getBlob(mimeString);

	//New Code
	return new Blob([ab], {type: mimeString});


}

export default {
	data() {
		return {
			imgDisplay: undefined,
			innerValue: undefined,
			cropping: false,
			imgApiData: undefined,
			img: undefined,
		}
	},
	props: {
		value: {
			type: String,
			default: ""
		},
		type: {},
	},
	created() {
		this.styleService = new StyleService();
	},
	mounted() {
		const vm = this;
		this.innerValue = this.value;

		if(this.value == undefined || this.value == ''){
		    return;
        }

		vm.styleService.getImage(vm.value)
				.then(res => {
					if(res.error === true){
						throw new Error(res.message);
					}
					vm.imgDisplay = res.data.bucketUrl + '/' + res.data.guid + '.' + res.data.extension;
				})
				.catch(err => {
					//todo display error
					console.error(err.message);
				});

	},
	methods: {
		updateValue() {
			this.$emit('input', this.innerValue)
		},
		proxyClick(){
			this.$refs.inputFile.click();
		},
		prepareCropImage() {
			const vm = this;

			if(this.$refs.inputFile.files.length <= 0){
				return;
			}

			let file = this.$refs.inputFile.files[0];
			if(file == undefined || file === null){
				return;
			}

			console.log('FN',this.$refs.inputFile.files[0].name);

			let reader  = new FileReader();
			// it's onload event and you forgot (parameters)
			reader.onload = function(e)  {
				vm.img = e.target.result;
				vm.$nextTick(() => {
					vm.cropping = true;
				})
			};
			// you have to declare the file loading
			reader.readAsDataURL(file);
		},
		cancelCrop(){
			this.img = '';
			this.cropping = false;
		},
		cropImage() {
			const vm = this;
			let filename = this.$refs.inputFile.files[0].name;


			// get image data for post processing, e.g. upload or setting image src
			this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();

			let blob = dataURItoBlob(this.cropImg);
			let fd = new FormData();
			fd.append("mfile", blob);
			fd.append("filename", filename);
			fd.append("type", vm.type);
			fd.append('extension', filename.split('.').pop());

			window.appData.$http.post('/apx/upd/file',
					fd,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
						}
					}
			)
					.then(function(data){
						//console.log(data, 'SUCCESS!!');
						//done replace this.innerValue
						if(data.data.error == true){
							throw new Error(data.message);
						}
						vm.innerValue = data.data['@object'].entity_id;
						vm.imgDisplay = data.data['@object'].url;
						//done update parent => emit
						vm.updateValue();
						vm.cancelCrop();
					})
					.catch(function(err){
						console.warn('FAILURE!!',err);
						vm.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					});
		},

	},
	components: {
		VueCropper
	}
}
</script>

<style scoped>

</style>
